.signup__bg{
    background-image: url(https://images.unsplash.com/photo-1533561797500-4fad4750814e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80);
    height: 90vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-x: hidden;
}

.signup-profile-pic__container{
    width: 100px;
    height: 100px;
    margin: 0 auto;
    position: relative;
}

.signup-profile-pic{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid black;
    object-fit: cover;
}

.add-picture-icon{
    position: absolute;
    bottom: 0;
    right: 10px;
    color: darkgreen;
    background: white;
    cursor: pointer;
    z-index: 69;
}