.member-status-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    object-fit: cover;
}

.member-status {
    margin-bottom: 0;
    position: relative;
}

.sidebar-online-status {
    color: green;
}

.sidebar-offline-status {
    color: red;
}

.sidebar-offline-status,
.sidebar-online-status {
    font-size: 11px;
    position: absolute;
    z-index: 69;
    bottom: 0;
    left: 12px;
}

.side-margin {
    margin-top: 10px;
}